<template>
    <v-card class="pb-3">
        <v-card-title class="primary white--text text-h5 py-2">
            <span v-t="'vvtList.exportDialog.title'" />
            <v-spacer />
            <v-btn
                color="primary"
                :outlined="false"
                fab
                small
                :elevation="2"
                @click="$emit('close')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title> 
        <v-card-subtitle class="mt-5">
            <v-alert
                prominent
                text
                type="info"
                icon="mdi-information-outline"
                class="mb-0"
                >
                <span v-t="'vvtList.exportDialog.steps.' + exportDialogStep" />
            </v-alert>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions class="pt-5">
            <v-select
                class="mr-2"
                :label="$t('vvtList.exportDialog.exportType.label')"
                :items="exportTypeItems"
                item-value="value"
                item-text="title"
                v-model="exportTypeModel"
                outlined
                hide-details
                clearable
            >
                <template v-slot:item="{ item }" >
                    <v-tooltip :disabled="!item.hint" bottom color="primary" z-index="300">
                        <template v-slot:activator="{ on: onTooltip }">
                            <div v-on="onTooltip" class="w100">
                                {{ item.title }}
                            </div>
                        </template>
                        <span v-text="item.hint" />
                    </v-tooltip>
                </template>
            </v-select>
        </v-card-actions>
        <v-card-actions>
            <v-select
                class="mr-2"
                :label="$t('vvtList.exportDialog.language.label')"
                :items="$t('vvtList.exportDialog.language.items')"
                item-value="languageCode"
                item-text="languageName"
                v-model="languageModel"
                outlined
                hide-details
                clearable
            ></v-select>
            <v-select
                :disabled="exportDialogStep < 3"
                class="mr-2"
                :label="$t('vvtList.exportDialog.exportFileType.label')"
                :items="exortFileTypeItems"
                item-value="value"
                item-text="title"
                v-model="exportFileTypeModel"
                outlined
                hide-details
                clearable
            ></v-select>
        </v-card-actions>
        <v-card-actions v-if="exportHistoryEnabled">
            <LeaDate
                :label="exportHistoryModel === null ? $t('vvtList.exportDialog.exportHistory.labelEmptyDate') : $t('vvtList.exportDialog.exportHistory.labelSelectedDate')"
                _picker-type="month"
                light-variant
                :prepend-icon="null"
                :min-date="minDate"
                :max-date="maxDate"
                :loading="$wait.is('loading min date')"
                :value="exportHistoryModel"
                @input="$emit('input', exportHistoryModel = $event)"
                class="w100 mr-2"

            />
        </v-card-actions>
        <v-card-actions>
            <v-btn
                :disabled="exportDialogStep < 4"
                color="primary"
                block
                @click.prevent="$emit('process-export')"
            >
                <v-icon left>cloud_download</v-icon>
                {{ $t('vvtList.exportDialog.buttonTitle') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import LeaDate from '@/components/Input/LeaDate';

export default {
    name: 'ExportDialog',
    components: {
        LeaDate
    },
    props: {
        selectedFilter: {
            type: [Object, String],
            default: null
        },
        exportType: {
            type: String,
            default: null
        },
        exportLanguage: {
            type: String,
            default: null
        },
        exportFileType: {
            type: String,
            default: null
        },
        exportHistoryDate: {
            type: String,
            default: null
        },
        singleDocumentExport: {
            type: Boolean,
            default: false
        },
        isSingleControllerActivity: {
            type: Boolean,
            default: false
        },
        isSingleProcessorActivity: {
            type: Boolean,
            default: false
        },
        minDate: {
            type: String,
            default: null
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    mounted() {
        const userLang = this.$getMyDetailProp('preferredLanguage');
        if(userLang !== null) {
            this.languageModel = userLang;
        }
    },
    methods: {
        authorityReportSelected () {
            return this.exportTypeModel === 'authority_controller' || this.exportTypeModel === 'authority_processor';
        }
    },
    computed: {
        maxDate () {
            let strDate = new Date();
            //strDate.setDate(strDate.getDate() - 1);
            return strDate.toISOString().substring(0, 10);
        },
        exportHistoryEnabled () {
            return this.authorityReportSelected();
        },
        exportDialogStep () {
            if (this.exportTypeModel && this.languageModel && this.exportFileTypeModel) {
                return 4;
            }
            if (this.exportTypeModel && this.languageModel) {
                return 3;
            }
            if (this.exportTypeModel) {
                return 2;
            }
            return 1;
        },
        disableAuthorityReport () {
            return this.selectedFilter && this.selectedFilter !== 'all' && this.selectedFilter.authorityReportRelevance && this.selectedFilter.authorityReportRelevance.length === 1 && parseInt(this.selectedFilter.authorityReportRelevance[0]) === 0;
        },
        disableControllerAuthorityReport () {
            return this.disableAuthorityReport || (this.singleDocumentExport && !this.isSingleControllerActivity);
        },
        disableProcessorAuthorityReport () {
            return this.disableAuthorityReport || (this.singleDocumentExport && !this.isSingleProcessorActivity);
        },
        exportTypeItems () {
            let arrTypes = [];
            if(!this.disableControllerAuthorityReport) {
                arrTypes.push({
                    value: 'authority_controller',
                    title: this.$i18n.t('vvtList.exportDialog.exportType.items.authority_controller.title'),
                    hint: this.$i18n.t('vvtList.exportDialog.exportType.items.authority_controller.hint')
                });
            }
            if(!this.disableProcessorAuthorityReport) {
                arrTypes.push({
                    value: 'authority_processor',
                    title: this.$i18n.t('vvtList.exportDialog.exportType.items.authority_processor.title'),
                    hint: this.$i18n.t('vvtList.exportDialog.exportType.items.authority_processor.hint')
                });
            }

            arrTypes.push({
                value: 'internal',
                title: this.$i18n.t('vvtList.exportDialog.exportType.items.internal.title'),
                hint: this.$i18n.t('vvtList.exportDialog.exportType.items.internal.hint')
            });

            if (!this.singleDocumentExport) {
                arrTypes.push({
                    value: 'monitoring',
                    title: this.$i18n.t('vvtList.exportDialog.exportType.items.monitoring.title'),
                    hint: this.$i18n.t('vvtList.exportDialog.exportType.items.monitoring.hint')
                });
            }
            return arrTypes;
        },
        exportTypeModel: {
            get() {
                return this.exportType;
            },
            set(val) {
                this.$emit('changeExportType', val);
                if(['internal', 'monitoring'].includes(val)){
                    this.exportFileTypeModel = 'excel';
                } else {
                    this.exportFileTypeModel = 'word';
                }
                this.exportHistoryModel = null;
            },
        },
        languageModel: {
            get() {
                return this.exportLanguage;
            },
            set(val) {
                val = val === null ? null : val.toUpperCase()
                this.$emit('changeLanguage', val);
            },
        },
        exportFileTypeModel: {
            get() {
                return this.exportFileType;
            },
            set(val) {
                this.$emit('changeExportFileType', val);
            },
        },
        exportHistoryModel: {
            get() {
                return this.exportHistoryDate;
            },
            set(val) {
                this.$emit('changeExportHistoryDate', val);
            },
        },
        exortFileTypeItems () {
            if (['authority_controller', 'authority_processor'].includes(this.exportTypeModel)) {
                return this.$i18n.t('vvtList.exportDialog.exportFileType.items').filter(x => (x.value === 'word' || x.value === 'pdf'))
            }
            if (this.exportTypeModel === 'internal') {
                return this.$i18n.t('vvtList.exportDialog.exportFileType.items').filter(x => x.value === 'excel')
            }
            if (this.exportTypeModel === 'monitoring') {
                return this.$i18n.t('vvtList.exportDialog.exportFileType.items').filter(x => x.value === 'excel')
            }
            return [];
        }
    },
};
</script>
